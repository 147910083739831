const modalOverlays = document.querySelectorAll('.modal-overlay');
if (modalOverlays) {
    modalOverlays.forEach((modalOverlay) => {
        modalOverlay.addEventListener('click', () => {
            const modal = modalOverlay.previousElementSibling;
            const ajaxLoader = modal.querySelector('.ajax-loader');
            if (!ajaxLoader || ajaxLoader.classList.contains('hidden')) {
                modal.classList.remove('visible');
                modal.setAttribute('aria-modal', 'false');
            }
        });
    });
}

const modals = document.getElementsByClassName('modal');

for (let i = 0; i < modals.length; i++) {
    const modal = modals[i];
    const modalContainer = modal.querySelector('.modal-container');
    const modalClose = modal.querySelector('a.close');
    modalClose.addEventListener('click', function() {
        modal.classList.remove('visible');
        modal.setAttribute('aria-modal', 'false');
    });
    modal.addEventListener('modalOpen', () => {
        modal.classList.add('visible');
        modal.setAttribute('aria-modal', 'true');
        if (modal.dataset.ajax !== 'false') {
            const request = new Request(modal.dataset.ajax);
            fetch(request, {
                method: 'POST',
                headers: {
                    'X-CSRF-TOKEN': modal.dataset.csrf,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    event: eventId,
                    tier: tierId
                })
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((json) => {
                        modalContainer.insertAdjacentHTML('beforeend', json.content);
                        const modalLoadEvent = document.createEvent('CustomEvent');
                        modalLoadEvent.initCustomEvent('modalLoad', false, false, {
                            location: modal.dataset.ajax,
                            data: json.data
                        });
                        document.dispatchEvent(modalLoadEvent);
                        modal.querySelector('.ajax-loader').classList.add('hidden');
                        modal.dataset.ajax = 'false';
                    });
                } else if (response.status === 401) {
                    window.location.href = '/login';
                } else {
                    let errorText = `Checkout failed, error ${response.status}`;
                    response.json()
                        .then((json) => {
                            console.log(json.error);
                            if (json.error) {
                                errorText += `: ${json.error}`;
                            }
                        })
                        .finally(() => {
                            const modalErrorEvent = document.createEvent('CustomEvent');
                            modalErrorEvent.initCustomEvent('modalError', false, false, {
                                location: modal.dataset.ajax,
                                errorText: errorText
                            });
                            document.dispatchEvent(modalErrorEvent);
                            modal.querySelector('.ajax-loader').classList.add('hidden');
                            modal.dataset.ajax = 'false';
                        });
                }
            });
        }
    });
}

const modalTriggers = document.querySelectorAll('[data-modal]');

if (modalTriggers) {
    modalTriggers.forEach((modalTrigger) => {
        modalTrigger.addEventListener('click', (event) => {
            event.preventDefault();
            const modal = document.getElementById(modalTrigger.dataset.modal);
            const modalOpenEvent = new Event('modalOpen');
            modal.dispatchEvent(modalOpenEvent);
        });
    });
}
